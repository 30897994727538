import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return { user: null, lastUsedUserName: null }
  },  
  persist: true,
  actions: {
    setUser(user) {
      this.user = user;
      if(user){
        this.lastUsedUserName = user.userName
      }      
    },
    get(){
        if(this.user) {
            return this.user;
        }
        return null;
    },
    getLastUsedUserName(){

      return this.lastUsedUserName;
    },
    getSalesTabAccess() {
      return this.user.canAccessExtendedSearchSales;
    }
  },
})