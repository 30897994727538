import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from "@/stores/auth";

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import(/* webpackChunkName: "root" */ '../views/Root.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    beforeEnter:[function() {
      var user = useAuthStore().get();
      if(user){
        return { name:"search" };
      }
    }]
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () => import(/* webpackChunkName: "forgotpass" */ '../views/ForgotPasswordView.vue'),
    props: (route) => ({guid: route.query.id})
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to) => {
  
  if(!(to.name === 'login' || to.name === 'forgotpassword' || to.name === 'root'))
  {
    var user = useAuthStore().get();
    if(!user) {
      return {name:"login"};
    }
  }  
})

export default router
