import 'devextreme/dist/css/dx.common.css';
import '@/assets/custom-devex-theme/dx.generic.custom-scheme.css';
import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import '@fortawesome/fontawesome-free/css/all.css'

import VueMobileDetection from "vue-mobile-detection";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)
createApp(App).use(router).use(pinia).use(VueMobileDetection).mount('#app')
