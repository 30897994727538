<template>
  <DxToast v-model:visible="visible" v-model:message="toastMessage" v-model:type="toastType" />
</template>

<script>
import { DxToast } from "devextreme-vue/toast";

var component = {
  name: "LoginView",
  components: {
    DxToast
  },
  mounted() {
    var $this = this;
    document.addEventListener("toastError", (ev) => {
      const { message } = ev.detail
      $this.toastMessage = message;
      $this.toastType = "error";
      $this.visible = true;
    });
    document.addEventListener("toastSuccess", (ev) => {
      const { message } = ev.detail
      $this.toastMessage = message;
      $this.toastType = "success";
      $this.visible = true;
    });
    document.addEventListener("toastInfo", (ev) => {
      const { message } = ev.detail
      $this.toastMessage = message;
      $this.toastType = "info";
      $this.visible = true;
    });
    document.addEventListener("toastWarning", (ev) => {
      const { message } = ev.detail
      $this.toastMessage = message;
      $this.toastType = "warning";
      $this.visible = true;
    });
  },
  data: function () {
    return {
      toastMessage: "",
      toastType: "error",
      visible: false
    };
  }
};

export const toast = {
  toastError(message) {
    // eslint-disable-next-line 
    var $component = component;
    document.dispatchEvent(
      new CustomEvent("toastError", {detail:{message}})
    )
  },
  toastSuccess(message) {
    document.dispatchEvent(
      new CustomEvent("toastSuccess", {detail:{message}})
    )
  },
  toastInfo(message) {
    document.dispatchEvent(
      new CustomEvent("toastInfo", {detail:{message}})
    )
  },
  toastWarning(message) {
    document.dispatchEvent(
      new CustomEvent("toastWarning", {detail:{message}})
    )
  },
};

export default component;
</script>